
<script lang="ts" setup>
const iconSize = "50px";
</script>

<template>
    <div class="footer-container">
        <div class="social">
            <a href="https://www.facebook.com/KatarraktisRefuge" target="_blank">
                <Icon :size="iconSize" name="mdi:facebook"/>
                <span class="sr-only">Visit the facebook page`</span>
            </a>
            <a href="https://www.instagram.com/katarraktis.refuge/" target="_blank">
                <Icon :size="iconSize" name="mdi:instagram"/>
                <span class="sr-only">Visit the instagram page</span>
            </a>
            <a href="https://www.youtube.com/@katarraktisrefuge9897" target="_blank">
                <Icon :size="iconSize" name="mdi:youtube"/>
                <span class="sr-only">Visit the youtube page</span>
            </a>
        </div>

        <div class="all-links">
            <div class="list">
                <nuxt-link style="font-weight: 700; font-size: xx-large" to="/refuge">{{ $t('The Refuge') }}</nuxt-link>
                <nuxt-link to="/refuge/story">{{ $t('Our Story') }}</nuxt-link>
                <nuxt-link to="/refuge/booking">{{ $t('Booking') }}</nuxt-link>
                <nuxt-link to="/refuge/approach">{{ $t('How to get here') }}</nuxt-link>
                <nuxt-link to="/refuge/photos">{{ $t('Photos') }}</nuxt-link>
                <nuxt-link to="/refuge/videos">{{ $t('Videos') }}</nuxt-link>
            </div>
            <div class="list">
                <nuxt-link style="font-weight: 700; font-size: xx-large" to="/services">{{ $t('Services') }}</nuxt-link>
                <nuxt-link to="/services/accommodation">{{ $t('Accommodation') }}</nuxt-link>
                <nuxt-link to="/services/menu">{{ $t('Menu') }}</nuxt-link>
            </div>
            <div class="list">
                <nuxt-link style="font-weight: 700; font-size: xx-large" to="/activities">{{ $t('Activities') }}</nuxt-link>
                <nuxt-link to="/activities/hiking-trails">{{ $t('hikingTrails') }}</nuxt-link>
                <nuxt-link to="/activities/alpine">{{ $t('Alpine') }}</nuxt-link>
            </div>

        </div>
        <div class="footer_copyright">
            <p>&copy; 2023 Christos Kordoulas </p>
        </div>
    </div>
</template>

<style lang="sass">
.sr-only
    position: absolute
    width: 1px
    height: 1px
    padding: 0
    margin: -1px
    overflow: hidden
    clip: rect(0,0,0,0)
    border: 0

.all-links
    display: grid
    grid-template-columns: repeat(3, minmax(0, 1fr))
    width: 60%
    text-align: center

.list
    display: flex
    flex-direction: column

.social
    display: flex
    width: 10%
    justify-content: space-around
    margin-bottom: 10px

.dark-mode .footer-container
    background-color: #141414
    a
        color: white

.footer-container
    padding: 20px
    width: 100vw
    min-height: 100px
    display: flex
    flex-direction: column
    align-items: center

    a
        color: rgb(30, 30, 30)
        text-decoration: none
        font-size: 1.4rem

        transition: all 100ms ease

        &:hover
            color: #60cca5
            cursor: pointer

@media screen and (max-width: 1280px)
    .footer-container
        padding: 20px 0
    .all-links
        grid-template-columns: repeat(1, 1fr)

</style>
