import { default as alpineQ5BnCaRWutMeta } from "/app/pages/activities/alpine.vue?macro=true";
import { default as hiking_45trailsRV2vQ8jBE9Meta } from "/app/pages/activities/hiking-trails.vue?macro=true";
import { default as indexrEHaOSkTrQMeta } from "/app/pages/activities/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as newsWdQv0BaSvYMeta } from "/app/pages/news.vue?macro=true";
import { default as approachwBzp8UYgnrMeta } from "/app/pages/refuge/approach.vue?macro=true";
import { default as bookingV34BHKXWLhMeta } from "/app/pages/refuge/booking.vue?macro=true";
import { default as indexhgmjUvh5HbMeta } from "/app/pages/refuge/index.vue?macro=true";
import { default as photosy8QBzmLDYcMeta } from "/app/pages/refuge/photos.vue?macro=true";
import { default as storyyEdJmj08VQMeta } from "/app/pages/refuge/story.vue?macro=true";
import { default as videosUSPrxuySgfMeta } from "/app/pages/refuge/videos.vue?macro=true";
import { default as accommodationR5Mz0R40IsMeta } from "/app/pages/services/accommodation.vue?macro=true";
import { default as indexmjydbmT8M8Meta } from "/app/pages/services/index.vue?macro=true";
import { default as menuvA37nb5ALxMeta } from "/app/pages/services/menu.vue?macro=true";
export default [
  {
    name: "activities-alpine___en",
    path: "/en/activities/alpine",
    component: () => import("/app/pages/activities/alpine.vue").then(m => m.default || m)
  },
  {
    name: "activities-alpine___el",
    path: "/activities/alpine",
    component: () => import("/app/pages/activities/alpine.vue").then(m => m.default || m)
  },
  {
    name: "activities-hiking-trails___en",
    path: "/en/activities/hiking-trails",
    component: () => import("/app/pages/activities/hiking-trails.vue").then(m => m.default || m)
  },
  {
    name: "activities-hiking-trails___el",
    path: "/activities/hiking-trails",
    component: () => import("/app/pages/activities/hiking-trails.vue").then(m => m.default || m)
  },
  {
    name: "activities___en",
    path: "/en/activities",
    component: () => import("/app/pages/activities/index.vue").then(m => m.default || m)
  },
  {
    name: "activities___el",
    path: "/activities",
    component: () => import("/app/pages/activities/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___el",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "news___en",
    path: "/en/news",
    component: () => import("/app/pages/news.vue").then(m => m.default || m)
  },
  {
    name: "news___el",
    path: "/news",
    component: () => import("/app/pages/news.vue").then(m => m.default || m)
  },
  {
    name: "refuge-approach___en",
    path: "/en/refuge/approach",
    component: () => import("/app/pages/refuge/approach.vue").then(m => m.default || m)
  },
  {
    name: "refuge-approach___el",
    path: "/refuge/approach",
    component: () => import("/app/pages/refuge/approach.vue").then(m => m.default || m)
  },
  {
    name: "refuge-booking___en",
    path: "/en/refuge/booking",
    component: () => import("/app/pages/refuge/booking.vue").then(m => m.default || m)
  },
  {
    name: "refuge-booking___el",
    path: "/refuge/booking",
    component: () => import("/app/pages/refuge/booking.vue").then(m => m.default || m)
  },
  {
    name: "refuge___en",
    path: "/en/refuge",
    component: () => import("/app/pages/refuge/index.vue").then(m => m.default || m)
  },
  {
    name: "refuge___el",
    path: "/refuge",
    component: () => import("/app/pages/refuge/index.vue").then(m => m.default || m)
  },
  {
    name: "refuge-photos___en",
    path: "/en/refuge/photos",
    component: () => import("/app/pages/refuge/photos.vue").then(m => m.default || m)
  },
  {
    name: "refuge-photos___el",
    path: "/refuge/photos",
    component: () => import("/app/pages/refuge/photos.vue").then(m => m.default || m)
  },
  {
    name: "refuge-story___en",
    path: "/en/refuge/story",
    component: () => import("/app/pages/refuge/story.vue").then(m => m.default || m)
  },
  {
    name: "refuge-story___el",
    path: "/refuge/story",
    component: () => import("/app/pages/refuge/story.vue").then(m => m.default || m)
  },
  {
    name: "refuge-videos___en",
    path: "/en/refuge/videos",
    component: () => import("/app/pages/refuge/videos.vue").then(m => m.default || m)
  },
  {
    name: "refuge-videos___el",
    path: "/refuge/videos",
    component: () => import("/app/pages/refuge/videos.vue").then(m => m.default || m)
  },
  {
    name: "services-accommodation___en",
    path: "/en/services/accommodation",
    component: () => import("/app/pages/services/accommodation.vue").then(m => m.default || m)
  },
  {
    name: "services-accommodation___el",
    path: "/services/accommodation",
    component: () => import("/app/pages/services/accommodation.vue").then(m => m.default || m)
  },
  {
    name: "services___en",
    path: "/en/services",
    component: () => import("/app/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: "services___el",
    path: "/services",
    component: () => import("/app/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: "services-menu___en",
    path: "/en/services/menu",
    component: () => import("/app/pages/services/menu.vue").then(m => m.default || m)
  },
  {
    name: "services-menu___el",
    path: "/services/menu",
    component: () => import("/app/pages/services/menu.vue").then(m => m.default || m)
  }
]